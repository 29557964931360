<template>
  <div class="user">
      <div class="line_one">
        <el-form inline :model="queryInfo" label-width="80px" ref="form">
        <el-form-item label="手机号:">
          <el-input v-model="queryInfo.mobile" placeholder="请输入手机号" clearable />
        </el-form-item>
        <el-form-item label="姓名:">
          <el-input v-model="queryInfo.userName" placeholder="请输入姓名" clearable />
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
         <el-form-item>
          <el-button class="btn" type="primary" @click="search()">搜索</el-button>
        </el-form-item>
      </el-form>
      </div>
      <div class="line_two">
        <el-table :data="dataUserList" style="width: 100%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
        <el-table-column label="序号" type="index" align="center" width="80"/>
        <el-table-column label="头像" width="150" align="center">
          <template v-slot="{ row }">
            <div class="flex">         
              <el-popover
                placement="right-start"
                width="250"
                trigger="click"
              >
              <div class="hover_item">
                <div class="headImg">
                  <img :src="row.headPortrait">
                </div>
                <div class="user_tip">
                  <div class="user_name">
                    <div class="black_one">{{row.userName}}</div>
                    <div class="icon_man">
                      <img src="../../../../static/images/jr-icon-man.png" v-if="row.gender == 2">
                      <img src="../../../../static/images/jr-icon-female.png" v-if="row.gender == 1">
                    </div> 
                  </div>
                  <div class="tip">关注的“X+AI”领域: {{detailList.label}}</div>
                  <div class="tip">加入时间: {{detailList.joinTime}}</div>
                  <div class="tip">登录次数: {{detailList.loginCount}}</div>
                  <div class="tip">所在行业: {{detailList.industry}}</div>
                </div>
              </div>
                <img :src="row.headPortrait" slot="reference" @click="selectPart(row.id)" style="width:45px;height:45px"/>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="真实姓名" prop="userName" align="center" width="120"/>
        <el-table-column label="手机号" prop="mobile" align="center" width="120"/>
        <el-table-column label="机构" prop="institution" align="center" width="100"/>
        <el-table-column label="职位" prop="position" align="center"  width="120" />
        <el-table-column label="最近登陆时间" prop="lastLoginTime" align="center"  width="160"/>
        <el-table-column label="IP/城市名" align="center">
        <template v-slot="{ row }">
          <div>
            {{row.ip}}&nbsp;|&nbsp;{{row.ipCity}}
          </div>
        </template>
        </el-table-column>
        <el-table-column label="用户等级" prop="whetherMember" align="center" width="100">
          <template v-slot="{ row }">
          <div>
            {{row.whetherMember == 0 ? '普通用户' : '会员用户'}}
          </div>
        </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="120">
          <template v-slot="{ row }">
            <div class="stage notPass" v-if="row.status == '未审核'" >未审核</div>
            <div class="stage Pass" v-if="row.status == '已通过'">已通过</div>
            <div class="stage reject" v-if="row.status == '已拒绝'">已拒绝</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="280px">
          <template v-slot="{ row }">
            <div class="btnArea" v-if="row.status == '未审核'">
              <div class="blueButton" style="margin:0px 10px" @click="PassOne(row)">通过</div>
              <div class="skyButton" style="margin:0px 20px 0px 0px" @click="RejectOne(row)">拒绝</div>
              <div class="blue" @click="goDetail(row)">查看详情</div>
            </div>
            <div v-else class="blue" @click="goDetail(row)">查看详情</div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.current"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
  </div>
</template>
<script>
import { pagination } from '@/mixins/pagination'
import { getAllUser, selectPart, updateConsent, userRefund ,updateConsentRefuse} from '@/api/userManagement.js'
const defaultQueryInfo = Object.freeze({
  current: 0,
  size: 10,
})
export default {
  name: 'User',
  mixins: [pagination],
  data() {
    return {
      dataUserList: [],
      detailList: [],
      selectionArr: [],
      loading: false,
      dataList:{},
      queryInfo: { ...defaultQueryInfo },
      id:''
    }
  },
  created() {
    this.search()
  },
  methods: {
    //重置查询条件
    reset() {
      this.queryInfo = {
        pageNum: 0,
        pageSize: 10,
      }
      this.search()
    },
    goDetail(row) {
      this.$router.push({
        path: '/userManagement/userDetail?id='+row.id,
      })
    },
    async search() {
      this.loading = true
      const { data: res } = await getAllUser(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    async selectPart(id) {
      const { data: res } = await selectPart({id:id})
      // console.log(res,"res");
      if (res.resultCode === 200) {
        this.detailList = res.data
      }
    },
    async PassOne(row) {
      this.$confirm(`确认通过用户${row.userName}的会员申请吗 ?`, {
        type: 'warning'
      })
        .then(async() => {
          const { data: res } = await updateConsent({id:row.id})
          if (res.resultCode === 200) {
            this.$message.success(`通过成功`)
            this.search()
          } else {
            this.$message.error('操作失败，请重试。')
          }
        })
        .catch(() => {})
    },
    // 会员退款
   async RejectOne(row) {
      this.id=row.id
      let that =this
      this.$confirm(`确认拒绝用户${row.userName}的会员申请吗 ?`, {
        type: 'warning'
      })
        .then(async () => {
          that.updateConsentRefuse()
          const { data: res } = await userRefund({
            orderNumber:row.orderNumber,
            RefundDeclaration:'退款理由'
            })
          if (res.resultCode === 200) {
            this.$message.success(`拒绝成功`)
            this.search()
          } else {
            this.$message.error('操作失败，请重试。')
          }
        })
        .catch(() => {})
    },
      async updateConsentRefuse(){
      const res =await updateConsentRefuse({id:this.id})
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  .line_one{
    background: #ffffff;
    border-radius: 12px;
    padding-top: 20px;
  } 
  .line_two{
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
  }
  .stage{
    margin: 0px auto;
    width: 64px;
    height: 24px;
    border-radius: 16px;
  }
  .notPass{
    background: #FBE2B7;
    color: #EDA00A;
  }
  .Pass{
    background: rgba(124, 113, 225, 0.3);
    color: #7C71E1;
  }
  .reject{
    background: #C4C4C4;
    color: #333;
  }
  .blue{
      margin-top: 3px;
      color: #3562FF;
      cursor: pointer;
    }
  .btnArea{
    display: flex;
    align-items: center;
    .blueButton,.skyButton{
      padding: 2px 15px;
      border-radius: 5px;
      cursor: pointer;
    }
    .skyButton:hover{
      background: #FB6956;
      color: #ffffff !important;
      transition: all 0.2s;
    }
    .blueButton:hover{
      background: #3562FF;
      color: #ffffff !important;
      transition: all 0.2s;
    }
    .blueButton{
      border: #3562FF 1px solid;
      color: #3562FF;
    }
    .skyButton{
      border: 1px solid #FB6956;
      color: #FB6956;
    }
    
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
  .data_list {
    width: 100%;
    margin: 20px 0px 0px 15px;
  }

  // 全局css 加上以下代码，可以隐藏上下箭头
  // 取消input的上下箭头
  /deep/input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input[type='number'] {
    -moz-appearance: textfield;
  }
}
.hover_item {
  display: flex;
  height: 100px;
  .headImg{
    width: 64px;
    height: 64px;
    margin-right: 10px; 
    img{
      width: 100%;
      height: 100%;
    }
  }
  .user_tip{
    .user_name{
      display: flex;
      .black_one{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-right: 10px;
      }
      .icon_man{
        margin-top: 3px;
        width: 16px;
        height: 14px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .tip{
      font-size: 10px;
      line-height: 20px;
      color: #949090;
    }
  }
}
</style>
